<template>
  <span
      class="current-tag"
      :style="'background-color:'+color_bg+';color:'+color_fg+';border-color:'+color_fg"
      v-b-tooltip:hover :title="description"> {{ id }}
            <b-badge v-if="remove" class="current-tag-badge" @click="remove(id)">
              <b-icon icon="x-circle-fill"></b-icon>
            </b-badge>
  </span>
</template>

<script>
const Color = require('color');
export default {
  name: "Tag",
  props: {
      id: {
        type: String,
        required: true,
      },
      description: {
        type: String,
        default: "",
        required: false,
      },
      color: {
        type: String,
        default: "#000000",
      },
      remove: {
        type: Function,
        default: null,
        required: false
      }
  },
  computed: {
    color_bg: vm => Color(vm.color).lightness(30).hex(),
    color_fg: vm => Color(vm.color).lightness(80).hex(),
  }
}
</script>

<style scoped>
.current-tag {
  border-radius: 30rem !important;
  padding: 3px 10px;
  margin: 10px 0 30px 0;
  font-weight: bold;
  font-size: small;
}

.current-tag-badge {
  cursor: pointer;
  background-color: rgba(0,0,0,0);
}

</style>